import React from 'react';
import { Link } from 'gatsby';
import styles from './index.module.scss';

export default ({ className, children, outside, ...props }) => {
  const Component = outside ? 'a' : Link;
  return (
    <Component {...props} className={`${styles.root} ${className || ``}`}>
      <span>{children}</span>
    </Component>
  );
};
