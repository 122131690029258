import { Link } from 'gatsby';
import React from 'react';
import Container from '../Container';
import logo from '../../../static/logo.svg';
import styles from './index.module.scss';

export default ({ className, animate = false }) => (
  <header className={`${styles.root} ${className || ''}`}>
    <Container>
      <Link to="/">
        <img className={`${styles.logo} ${animate ? styles.animate : ``}`} src={logo} alt="Oops Typo Ltd" />
      </Link>
    </Container>
  </header>
);
